import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';

import {
	fadeInOutAnimation,
	flyUpAnimation
} from '../../../commons/animations/modalAnimations';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ApiCommunicationService } from '../../../shared/model/services/api-communication/api-communication.service';
import { User } from '../../../shared/model/data/user/User';
import { ProfileChangeEventService } from '../../../profile/services/profile-change-event.service';
import { UserSessionService } from '../../../auth/session/services/user-session/user-session.service';

@Component({
	selector: 'app-profile-image-upload-modal',
	templateUrl: './profile-image-upload-modal.component.html',
	styleUrls: ['./profile-image-upload-modal.component.scss'],
	animations: [fadeInOutAnimation, flyUpAnimation]
})
export class ProfileImageUploadModalComponent implements OnInit, AfterViewInit {
	public modalRef: NgbModalRef;
	public isLoading = false;

	public imageChangedEvent: any = '';
	public croppedImage: any = '';

	public fileName: string;

	constructor(
		private api: ApiCommunicationService,
		private profileChangeEventService: ProfileChangeEventService,
		public userSessionService: UserSessionService
	) // @Inject(ProfileChangeEventService) private profileChangeEventService: ProfileChangeEventService,
	{}

	ngAfterViewInit(): void {}

	ngOnInit() {}

	fileChangeEvent(event: any): void {
		this.fileName = event.srcElement.files[0].name;
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		if (event.base64) {
			this.croppedImage = event.base64;
		} else if (event.objectUrl) {
			fetch(event.objectUrl)
				.then((res) => res.blob())
				.then((blob) => {
					const reader = new FileReader();
					reader.onloadend = () => {
						this.croppedImage = reader.result as string;
					};
					reader.readAsDataURL(blob);
				});
		}
	}

	imageLoaded() {
		// show cropper
		// console.log("show cropper");
	}
	cropperReady() {
		// cropper ready
		// console.log("cropper ready");
	}
	loadImageFailed() {
		// show message
		// console.log("load image failed");
	}

	upload() {
		this.isLoading = true;

		const file = this.dataURLtoFile(this.croppedImage, this.fileName);

		this.api
			.profile()
			.uploadSingleProfileImage(file)
			.then((user: User) => {
				// console.log(
				// 	'Emitting profile image update event with user:',
				// 	user
				// );

				// this.profileChangeEventService.updateUser(user);
				this.userSessionService.updateUser(user);
				// this.profileChangeEventService.onUserUpdateEvent(user);
				// this.profileChangeEventService.onProfileImageUpdateEvent(user);
				this.modalRef.close();
			});
	}

	private dataURLtoFile(dataurl: string, filename: string): File {
		const arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
}
