import { EventEmitter, Inject, Injectable } from '@angular/core';
import { User } from '../../shared/model/data/user/User';
import { ApiCommunicationService } from '../../shared/model/services/api-communication/api-communication.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProfileChangeEventService {
	private _userUpdateEvent = new EventEmitter<User>();
	private _followEvent = new EventEmitter<Array<User>>();
	// private _profileImageUpdateEvent = new EventEmitter<User>(); // New EventEmitter

	private userSource = new BehaviorSubject<User>(null);
  	user$ = this.userSource.asObservable();

	constructor(
		@Inject(ApiCommunicationService) private api: ApiCommunicationService
	) {}

	public updateUser(user: User): void {
		this.userSource.next(user);
	}

	public onUserUpdateEvent(user: User) {
		console.log('onUserUpdateEvent user', user);
		
		this._userUpdateEvent.emit(user);
	}

	get userUpdateEvent(): EventEmitter<User> {
		return this._userUpdateEvent;
	}

	public onFollowEvent(userId: string) {
		this.api
			.relationship()
			.getFollowers(userId)
			.subscribe((followers: Array<User>) => {
				this._followEvent.emit(followers);
			});
	}

	get followEvent(): EventEmitter<Array<User>> {
		return this._followEvent;
	}
}
